import React from 'react';
import { ProgressBar, Step } from 'react-step-progress-bar';
import 'react-step-progress-bar/styles.css';
import {
  FaBox,
  FaWarehouse,
  FaShip,
  FaBuilding,
  FaTruck,
  FaCheck
} from 'react-icons/fa';
import styled from 'styled-components';

export const CustomProgressBar = ({ totalSteps, searchData }) => {
  const renderStepIcon = (index) => {
      switch (index + 1) {
          case 1:
              return <FaBox />;
          case 2:
              return <FaWarehouse />;
          case 3:
              return <FaShip />;
          case 4:
              return <FaBuilding />;
          case 5:
              return <FaTruck />;
          case 6:
              return <FaCheck />;
          default:
              return null;
      }
  };
  console.log(searchData.Status)
  let currentStep
  
  if(searchData.Status==="Coleta"){
  currentStep=1}
  else if(searchData.Status==="Depósito JP"){
    currentStep=2
  }else if(searchData.Status==="Bagagem em transito maritimo"||searchData.Status==="Bagagem em Transito Maritimo"||searchData.Status==="bagagem em transito maritimo"){
    currentStep=3
  }else if(searchData.Status==="Alfândega BR"||searchData.Status==="Alfândega br"||searchData.Status==="Alfandega BR"||searchData.Status==="alfandega br"){
  currentStep=4
  }else if(searchData.Status==="Destino Final"||searchData.Status==="Destino final"||searchData.Status==="destino final" ||searchData.Status==="Seguindo para o destino final"){
    currentStep=5
  }else{
    currentStep=6
  }


  return (    <ProgressBarContainer>
  <StepIcons>
    {Array.from({ length: totalSteps }, (_, index) => (
      <StepIcon key={index} >
          {renderStepIcon(index === searchData - 1 ? searchData - 1 : index)}
      </StepIcon>
      
    ))}
  </StepIcons>
  <ProgressBar
    percent={(currentStep / totalSteps) * 100}
    filledBackground="linear-gradient(to right, #007bff, #17a2b8)"
  >
    {Array.from({ length: totalSteps }, (_, index) => (
      <Step key={index} transition="scale">
        {({ accomplished }) => (
          <div className={`step ${accomplished ? 'completed' : ''}`} />
        )}
      </Step>
    ))}
  </ProgressBar>
  <StepIcons >
      <Text>Coleta</Text>
      <Text>Depósito JP</Text>
      <Text>Bagagem em <br/>transito maritimo</Text>
      <Text>Alfândega BR</Text>
      <Text>Destino Final </Text>
      <Text>Entregue</Text>
  </StepIcons>

</ProgressBarContainer>
);
};

const StepIcons = styled.div`
height: 30px;
display: flex;
justify-content: space-between;
align-items: center;
@media (max-width: 768px) {

}
`
const StepIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-bottom: 5px;
  @media (max-width: 768px) {
    flex-direction: column;

}
  `
const ProgressBarContainer = styled.div`
  margin-bottom: 30px;
  width: 800px;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
}`

const Text = styled.p`
font-weight: bolder;
font-size: 15px;
@media (max-width: 768px) {
    margin-top: 10%;
    font-size: 12px;
}
`

