import NavBar from "./components/NavBar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GeralDash } from "./components/dashboard/GeralDash";
function App() {
  return (
  <BrowserRouter>
  <Routes>
    <Route path="/" element={<GeralDash/>} />
    <Route path="/dashboard" element={<GeralDash/>} />

    </Routes>
    </BrowserRouter>
  );
}

export default App;
